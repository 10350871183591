#root {
  margin: 0;
  --radius: 7px;
  --white: white;
  --bg: #090909;
  --light: #494949;
  --dark: #0E0E0E;
  --blue: rgb(45,122,214,1);
  --green: #32a834;
  --red: #b31b1b;
}

body {
  margin: 0;
  min-height: 250rem;
  background-color: #090909;
  overflow-x: hidden;
}





#particles canvas {
  top: 0;
  z-index: -1;
  position: absolute;
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: 'Light';
  src: url('./fonts/SFProDisplay-Light.woff2') format('woff2'),
      url('./fonts/SFProDisplay-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Medium';
  src: url('./fonts/SFProDisplay-Medium.woff2') format('woff2'),
      url('./fonts/SFProDisplay-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Bold';
  src: url('./fonts/SFProDisplay-Bold.woff2') format('woff2'),
      url('./fonts/SFProDisplay-Bold.woff') format('woff');
  font-display: swap;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1;
}

h1 {
  font-family: Bold !important;
  color: var(--white);
}

header {
  font-family: Bold;
  color: var(--white);
}

p {
  font-family: Medium;
  color: var(--white);
}

h4 {
  font-family: Light;
}


nav {
  z-index: 99;
}



.nav-normal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  padding: 1rem 5rem;
  height: 4rem;
}

.nav-normal-disabled {
  user-select: none;
  opacity: 0;
}

.nav-fixed {
  position: fixed;
  top: 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #0E0E0E;
  height: 10px;
  transition: all ease-in-out 300ms;
}

.nav-fixed-container {
  transition: all ease-in-out 300ms;
  display: flex;
  opacity: 0;
  align-items: center;
  justify-content: space-between;
  height: 4;
  padding: 0 5rem;
  transform: translateY(-4rem);
  max-width: 100%;
  width: 100%;
}

.nav-fixed-open {
  max-width: 100%;
  height: 5rem;
  opacity: 0.96;
  backdrop-filter: blur(5px);
}

.nav-fixed-open  .nav-fixed-container  {
  opacity: 1;
  transform: translateY(0rem);
}



.nav-fixed-disabled {
  user-select: none;
  opacity: 0;
}

.nav-fixed-disabled .nav-fixed-container {
  display: none;
}

.nav-fixed-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(50% - 1.25rem);
  height: 2.5rem;
  background-color: var(--dark);
  width: 2.5rem;
  transition: all ease-in-out 300ms;
  border-radius: 100%;
  bottom: -1.25rem;
}

.nav-fixed-open .nav-fixed-arrow  {
  bottom: -1.25rem;
}

.nav-fixed-arrow svg {
  color: var(--white);
  transform: translateY(-7px);
  rotate: 180deg;
}

.nav-author {
  height: fit-content;
  min-width: 10rem;
}

.nav-author h1{
  font-family: Bold;
}

.nav-author h4 {
  color: var(--light);
  margin-top: 10px;
}

.nav-links {
  display: flex;
  width: 100%;
  margin: 0 2rem;
  max-width: 25rem;
  justify-content: space-between;
}

.nav-links h4{
  color: var(--white);
  margin: 0 10px;
  padding: 1rem 0;
  cursor: pointer;
  transition: all ease-in-out 150ms;
}

.nav-links h4:hover{
  transform: translateY(-5px);
}




.nav-contact {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
  width: fit-content;
  min-width: 10rem;
  height: 1rem;
}

.nav-contact svg {
  color: var(--white);
  z-index: 2;
}

.nav-contact h4 {
  z-index: 1;
  transition: all ease-in-out 200ms;
  opacity: 0;
  color: var(--white);
  transform: translateX(2rem);
}

.nav-contact svg {
  margin-right: 5px;
}

.nav-contact:hover h4{
 opacity: 1;
 transform: translateX(-1rem);
}

.copy-alert {
  position: absolute;
  transition: all ease-in-out 150ms;
  top: -2rem;
  opacity: 0;
  transform: translateY(1.5rem);
  left: 25%;
  background-color: #181818;
  color: var(--white);
  font-size: 12px;
  font-family: Light;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}

.copy-alert-active {
  opacity: 1;

  transform: translateY(0);
}

section {
  padding: 0 5rem;
  min-width: calc(100% - 10rem);
  max-width: 50rem;
}

.home {
  height: 85vh;
  min-height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
  height: 100%;
}

.home header {
  margin-top: auto;
  font-size: 4rem;
}

.home header span {
  background: linear-gradient(125deg, rgba(45,122,214,1) 20%, rgba(156,220,254,1) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home svg {
  animation: arrow 2s ease-in-out infinite;
  margin-top: auto;
  color: var(--white);
  rotate: -90deg;
}

@keyframes arrow {
  0% {
    transform: translateX(0px);
  }


  50% {
    transform: translateX(7px);
  }


  100% {
    transform: translateX(0px);
  }
  
}

.faq {
  margin-top: 15rem;
  min-height: 35rem;
  max-width: 50rem;
  outline: bluehin solid;
}

.faq header {
  font-size: 3rem;
}

.faq-container {
  display: flex;
  width: 100%;
  height: fit-content;
  align-items: start;
  justify-content: start;
  margin-top: 3rem;
}

.faq-boxes {
  min-width: 45%;
  width: 45%;
  padding-right: 3rem;
}

.faq-box-closed {
  border-radius: var(--radius);
  margin-bottom: 10px;
  background-color: var(--dark);
  width: 100%;
  max-height: 4rem;
  overflow: hidden;
  transition: all ease-in-out 300ms;
}

.faq-box-open {
  border-radius: var(--radius);
  outline: var(--light) thin solid;
  margin-bottom: 10px;
  background-color: var(--dark);
  width: 100%;
  max-height: 10rem;
  overflow: hidden;
  transition: all ease-in-out 300ms;
}


.faq-icon {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
}

.faq-minus {
  margin: auto;
  position: absolute;
  height: 1px;
  background-color: var(--white);
  width: 100%;
}

.faq-plus {
  margin: auto;
  position: absolute;
  height: 0px;
  background-color: var(--white);
  width: 1px;
  transition: all ease-in-out 300ms;
}

.faq-box-closed .faq-plus {
  margin: auto;
  position: absolute;
  height: 15px;
  background-color: var(--white);
  width: 1px;
  transition: all ease-in-out 300ms;
}




.faq-question {
  height: 4rem;
  padding: 0 1rem;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.faq-answer {
  padding: 0 1rem 1rem 1rem;
}

.faq-answer h4 {
  color: var(--white);
}

.faq-answer span {
  color: var(--blue);
}


.faq-images {
  height: 100%;
  max-width: 100%;
  width: 100%;
}

.image-group img{
  margin-left: auto;
  max-width: 100%;
  max-height: 30rem;
  display: none;
  transition: all ease-in-out 300ms;
  border-radius: var(--radius);
}

.image-group .active-image {
  transform: translateX(80rem);
  animation: slide 4s;
  display: flex;
}

@keyframes slide {
  0% {
    transform: translateX(80rem);
  }
  10% {
    transform: translateX(0rem);
  }
  90% {
    transform: translateX(0rem);
  }
  100% {
    transform: translateX(80rem);
  }
}





.projects {
  margin-top: 25rem;
  padding: 0;
  min-width: 100% !important; 
  max-width: 100% !important;
}

.projects header {
  font-size: 3rem;
  margin-left: 5rem;
}

.projects-slider {
  margin-top: 3rem;
  padding: 0 0 2rem 0;
  mask: linear-gradient(90deg, transparent, white 1%, white 99%, transparent);
  min-width: calc(100% - 4.5rem) !important;
  max-width: calc(100% - 4.5rem) !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 100%;
  margin-left: 4.5rem;
  overflow-x: scroll;
  transition: all ease-in-out 300ms;
}

.projects-slider::-webkit-scrollbar-thumb {
  background-color: var(--light);
  border-radius: 2px;

}

.projects-slider::-webkit-scrollbar-thumb:hover {
  background-color: var(--white);
}


.projects-slider::-webkit-scrollbar {
  height: 10px;
}


.past-project {
  min-height: 20rem;
  margin-left: .5rem;
  margin-right: 1rem;
  min-width: calc(20rem * 1.777777778);
}

.domain {
  display: flex;
  cursor: pointer;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.domain-offline {
  font-size: 2rem;
  color: var(--light);
  margin-bottom: 1rem;
}

.domain svg {
  rotate: 90deg;
}

.website {
  background-color: var(--dark);
  border-radius: var(--radius);
  border: thin solid var(--light);
  min-height: 18rem;
  padding: 10px;
  min-width: calc(18rem * 1.777777778);
  border-radius: var(--radius);
}

.package-basic {
  padding: 10px;
  width: fit-content;
  border-radius: var(--radius);
  background-color: brown;
}

.package-intermediate {
  padding: 10px;
  width: fit-content;
  border-radius: var(--radius);
  background-color: orange;
}

.package-professional {
  padding: 10px;
  width: fit-content;
  border-radius: var(--radius);
  background-color: var(--blue);
}

.website-nav {
  margin: 0 auto;
  width: fit-content;
  display: flex;
}

.dot {
  height: 20px;
  margin: 0 10px;
  border-radius: 100%;
  width: 20px;
  background-color: var(--light);
}

.dot-active {
  height: 20px;
  margin: 0 10px;
  border-radius: 100%;
  width: 20px;
  background-color: var(--white);
}


.website-pictures {
  max-height: 17rem;
  min-height: 17rem;
  object-fit: cover;
  min-width: calc(17rem * 1.777777778);
  border-radius: var(--radius);
  overflow: hidden;
  margin: 10px 0;
}

.website-pictures img{
  width: 100%;
  display: none;
}



.website-pictures .image-active {
  display: flex;
}

.future-project {
  min-height: calc(20rem + 28px);
  margin-left: .5rem;
  margin-right: 2rem;
  min-width: calc(20rem * 1.777777778);
}

.future-project p {
  display: flex;
  cursor: pointer;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.future-website {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--dark);
  border-radius: var(--radius);
  border: thin solid var(--light);
  height: calc(20rem + 28px);
  min-height: calc(20rem + 28px);
  padding: 10px;
  min-width: calc(20rem * 1.777777778);
  border-radius: var(--radius);
}

.future-plus {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 2.1rem;
  background-color: var(--white);
  width: 1px;
  transition: all ease-in-out 300ms;
}

.future-plus:before {
  content: '';
  position: absolute;
  background-color: var(--white);
  width: 2.1rem;
  height: 1px;
}

.testimonials {
  margin-top: 25rem;
  width: 100%;
}

.testimonials header {
  font-size: 3rem;
}

.testimonials-container {
  display: flex;
  margin-top: 3rem;
  gap: 1rem;
  width: 100%;
}

.testimonial {
  height: fit-content;
  min-width: 34rem;
  border: thin solid var(--light);
  width: 34rem;
  padding: 10px;
  margin-bottom: 1rem;
  border-radius: var(--radius);
  background-color: var(--dark);
}

.testimonial-col {
  display: flex;
  flex-direction: column;
  width: 34rem;
  margin-right: 1rem;
}
.user {
  display: flex;
  align-items: center;
  height: 3rem;
  margin-bottom: 1rem;
  width: 100%;
}

.user-icon {
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
}

.user-info {
  margin-left: 10px;
}
.user-info h1{
  font-size: 1.3rem;
}

.user-info span {
  margin-left: 5px;
  font-size: 16px;
  align-items: center;
  font-family: light;
  display: inline-flex;
}

.user-info svg {
  margin-left: 5px;
}
.user-info h4{
  margin-top: 5px;
  font-size: 1rem;
  color: var(--light);
}

.review {
  width: 100%;
}

.review h4 {
  color: var(--white);
  font-size: 18px;
}


.prices {
  margin-top: 25rem;
  margin-bottom: 10rem;
  min-height: 35rem;
  max-width: 50rem;
}

.prices header {
  font-size: 3rem;
}

.prices-container {
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.price-card {
  max-width: 20rem;
  height: 28rem;
  max-height: 28rem;
  background-color: var(--dark);
  width: 20rem;
  padding: 1rem;
  border-radius: 10px;
  margin: 0 1rem;
  border: thin solid var(--light);
}


.price-card p:not(.feature, .feature-api) {
  text-align: center;
  font-size: 1.5rem;
}

.price-card h4 {
  text-align: center;
  color: var(--light);
  margin-top: 10px;
}

.price-card h1 {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.feature {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.feature svg {
  margin-right: 5px;
}

.cancel {
  color: var(--red);
}

.checkmark {
  color: var(--green);
}

.feature-api {
  display: flex;
  align-items: center;
  margin-left: 25px;
}

.feature-api svg {
  margin-right: 5px;
}

